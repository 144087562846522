import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, CommonService, EventEmitterType, EventService, ListService, UserService, Utilities } from '@SiteOwl/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'so-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'site-owl';
  returnUrl!: string;
  dashboardTabSelection!: string;
  removedOrInactiveCustomerSelectedSub!: Subscription;
  constructor(private router: Router, private route: ActivatedRoute, private eventService: EventService, private authService: AuthService, private listSerivce: ListService, private userService: UserService, private commonService: CommonService) {
    this.removedOrInactiveCustomerSelectedSub = this.eventService.subscribe(EventEmitterType.RemovedOrInactiveCustomerSelected, (r: any) => {
      this.authService.setCustomerId({ customerId: r, user: this.authService.getUserKeyData().user.email });
      this.userService.getUserDetails().subscribe({
        next: (result: any) => {
          this.listSerivce.getAdminSpecificRoleList().subscribe({
            next: (result: any) => {
              const adminRole: any = Utilities.seperateRoleDescription(result);
              this.commonService.setLocalStorageObject('adminRole', adminRole);
            }
          })
          this.authService.saveUserData(result);
          this.router.navigate(['secure/dashboard/customer']);
        }
      });

    })
  }
  ngOnDestroy(): void {
      if (this.removedOrInactiveCustomerSelectedSub) {
        this.removedOrInactiveCustomerSelectedSub.unsubscribe();
      }
  }
}
